import React from 'react'
import Loader from 'react-loader-spinner'
// import { Triangle } from 'react-loader-spinner'
// import { Spinner } from 'reactstrap'
import '../../assets/scss/style.scss'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
const CustomLoader = () => {
    // debugger
    return (
        // <div className="d-flex justify-content-center align-items-center">
        //     <div className="d-flex justify-content-center" style={{transform: "translate(-50%, -50%)"}}>
        //     <strong>Loading...</strong>
        //     {/* <Spinner /> */}
        //     {/* <Loader type="Triangle" color="#00BFFF" height={80} width={80} /> */}
        // </div>
        // </div>
        // overlay overlay-intensity-sm
        <div className='container overlay overlay-intensity-sm'>
            <div className='align-items-center contentLoader'>
                <Loader type="Triangle" color="#17a2b8" height={80} width={80} arialLabel="loading-indicator" />
                <br/>
                <h5 style={{fontWeight:600, marginLeft:'5px'}}>{" "}Loading...</h5>
            </div>
        </div>
        
        
    )
}

export default CustomLoader
