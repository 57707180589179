// ** Logo
// import logo from '@src/assets/images/logo/logo.png'
import logo from '@src/assets/images/logo/Sheer_logo.png'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../../../assets/scss/style.scss'
import { useSkin } from '../../../utility/hooks/useSkin'

const SpinnerComponent = () => {
  const layoutSkin = useSkin()
  
  useEffect(() => {
  }, [])
  
  return (
    <div className={layoutSkin[0] === 'dark' ? 'dark-layout' : ''} >
    <div className='d-flex flex-column align-items-center justify-content-center vh-100 loader-bg'>
      <img className='mb-1' height='100' src={logo} alt='logo' />
      <div className='brand-logo d-flex align-items-center' >
              <span className='brand-text text-primary ml-1 text-size-lg'>
                <b style={{fontWeight : 'bolder'}} >
                  SHEER.
                </b>EXCHANGE
              </span>
      </div>
    </div>
      {/* <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div> */}
    </div>
  )
}

export default SpinnerComponent
